export class UrlUtil {
  /**
   * Adds https if it's missing in a url
   *
   * @param url - The link URL
   * @returns The normalized URL
   */
  public static normalizeUrl(url?: string | null): string {
    if (!url?.trim()) {
      return '';
    }
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }

    return `https://${url.replace(/^https?:/, '')}`;
  }

  /**
   * Removes query params from URL
   *
   * @param url - URL to delete params from.
   * @param params - The params to remove
   * @returns The URL without the given query params
   */
  public static removeQueryParams(url: URL, params: Array<string>): URL {
    for (const param of params) {
      url.searchParams.delete(param);
    }

    return url;
  }

  /**
   * Encoded the given string as a URI component
   *
   * @param value - The value to be encoded
   * @returns The encoded value
   */
  public static encodeStringAsURIComponent(value: string): string {
    return encodeURIComponent(value);
  }

  /**
   * Returns current URL without environment scope and the language.
   *
   * @param url - URL string to replace language and scope.
   * @param scope - App scope (app, stage...)
   * @param lang - Current language
   *
   * @returns URL without scope and language.
   */
  public static removeLangAndScopeFromUrl(url: string, scope: string, lang: string): string {
    return url.replace(scope, '').replace(`/${lang}`, '');
  }

  /**
   * Adds the parameter to the given URL.
   *
   * @param url - URL to add the param to.
   * @param param - Param to add to the URL.
   * @param value - Value of the param
   *
   * @returns The final URL with the added param.
   */
  public static addQueryParamToUrl(url: string, param: string, value: string): string {
    const fullUrl = new URL(url);
    fullUrl.searchParams.set(param, value);
    return fullUrl.href;
  }
}
